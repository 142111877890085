import { nanoid } from 'nanoid';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, CardV2 as Card, InlineEdit, Layout, StepSection } from '@feathr/components';

interface IStep {
  id: string;
  isComplete: boolean;
  name: string;
}

function BuilderStep(): JSX.Element {
  const { t } = useTranslation();

  const [steps, setSteps] = useState([
    { id: nanoid(), isComplete: false, name: t('Untitled') },
  ] as IStep[]);

  function handleAdd(): void {
    setSteps([...steps, { id: nanoid(), isComplete: false, name: t('Untitled') }]);
  }

  function handleDelete(stepIndex: number): () => void {
    return (): void => {
      setSteps(steps.filter((_, i) => i !== stepIndex));
    };
  }

  return (
    <Layout>
      <Card>
        <Card.Header
          description={t(
            'Design the steps of your drip campaign. Each step has an email and automation rules that determine when and how that email should be sent.',
          )}
          title={t('Steps')}
        />
        {/* Do not use addVerticalGap={true} or the step lines will be too short */}
        <Card.Content>
          {steps.map((step, stepIndex) => {
            function handleChangeStepName(value?: string): void {
              setSteps(
                steps.map((s, i) => {
                  if (i === stepIndex) {
                    return { ...s, name: value ?? '' };
                  }
                  return s;
                }),
              );
            }

            return (
              <StepSection
                key={step.id}
                // Skip onDelete for the first step
                onDelete={stepIndex > 0 ? handleDelete(stepIndex) : undefined}
                stepIndex={stepIndex}
                stepNumber={stepIndex + 1}
              >
                <StepSection.Header>
                  <InlineEdit onChange={handleChangeStepName} value={step.name} />
                </StepSection.Header>
                <StepSection.Content />
              </StepSection>
            );
          })}
        </Card.Content>
        <Card.Actions>
          <Button onClick={handleAdd}>{t('Add step')}</Button>
        </Card.Actions>
      </Card>
    </Layout>
  );
}

export default BuilderStep;
